import logo from './logo.svg';
import './App.css';
import { Avatar, Card, Col, Row, Typography, Modal } from 'antd';
import avatarIco from './images/avatarbm.png';
import { useEffect, useState } from 'react';

import badge from './images/badge-psmi.svg';
function About() {
  const [modal, contextHolder] = Modal.useModal();

  const [animation, setAnimation] = useState(0);
  const items = [1,2,3,4,5];

  useEffect(() => {
    const t = setTimeout(() => {
      setAnimation(_animation => (_animation + 1) % 6)
    }, 3000);
    return () => clearTimeout(t)
  }, [animation])

  const toggleContactOpen = () => {
    modal.info({
      title: 'Contact Aaron',
      content: <code>I can be contacted by email for custom website & software requests at: <a className='link' href="mailto:aaronalnuttpro@gmail.com?subject=Reaching out from your website">aaronalnuttpro@gmail.com</a></code>,
      icon: null
    })
  }

  return (
    <div>
      <section className="About-body">
        {/* <img src={logo} className="App-logo" alt="logo" /> */}
        <p className='header' onClick={toggleContactOpen}>
          Services
        </p>
        <p className='info example-link' onClick={toggleContactOpen}>
          <ul className='service-list'>
            <li>Website Building</li>
            <li>Website Consulting</li>
            <li>Site Analytics</li>
            <li>Custom Mobile, Web, or Desktop Applications</li>
          </ul>
        </p>
        <p className='header' onClick={() => window.history.pushState({}, null, '/about')}>
          Education
        </p>
        <p className='info example-link' onClick={() => window.history.pushState({}, null, '/about')}>
          <span>B.S Computer Science - <a target='_blank' href="https://csm.rowan.edu/departments/cs/programs/bs_cs/">Rowan University</a></span>
        </p>
        <br />
        <p className='header' onClick={() => window.history.pushState({}, null, '/about')}>
          Certifications
        </p>
        <p className='info example-link' onClick={() => window.history.pushState({}, null, '/about')}>
          Professional Scrum Master 1
          <a href="https://www.credly.com/users/aaron-alnutt/badges" target="_blank"><img className='cert' src={badge} /></a>
        </p>
        <p className='header' onClick={() => window.history.pushState({}, null, '/about')}>
          Tech Stack
        </p>
        <Row className='stack-row' gutter={[30, 30]}>
          
            <Col className='example-link' flex={'1 1 100px'}  >
              <Typography.Title style={{ whiteSpace: 'nowrap'}} level={3}>Frontend</Typography.Title>

              <ul className='snug-list'>
                <li>React</li>
                <li>TypeScript</li>
                <li>Blazor</li>
                <li>Flutter</li>
                <li>HTML</li>
                <li>jQuery</li>
              </ul>
            </Col>
            <Col className='example-link' flex={'1 1 100px'}  >
              <Typography.Title style={{ whiteSpace: 'nowrap'}} level={3}>Backend</Typography.Title>
              <ul className='snug-list'>
                <li>C#</li>
                <li>Java</li>
                <li>Node.js</li>
                <li>Google Cloud Functions</li>
                <li>SQL</li>
                <li>NoSQL</li>
              </ul>
            </Col>
            <Col className='example-link' flex={'1 1 100px'} >
            <Typography.Title level={3}>Process</Typography.Title>

              <ul className='snug-list'>
                <li>Agile</li>
                <li>Scrum</li>
                <li>Kanban</li>
                <li>DevOps</li>
                <li>SOLID</li>
              </ul>
            </Col>
            <Col className='example-link' flex={'1 1 100px'}  ></Col>
        </Row>
        {/* <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a> */}
      </section>
      <div>{contextHolder}</div>
<br /><br />
    </div>
  );
}

export default About;
