import React from 'react';
import avatarIco from './images/avatarbm.png';

const Header = () => {  
    return (
        <header onClick={() => window.location = '/'} className="App-header ">
            <div className='firework'></div>
            <div className='firework'></div>
            <div className='firework'></div>     

            <img className="selfie" style={{ height: 100, width: 100 }} src={avatarIco}  />
            <code onClick={() => window.location = '/'}>@aaronalnutt</code>
        </header>
    )
};

export default Header;