import logo from './logo.svg';
import './App.css';
import { Avatar, Modal, message } from 'antd';
import avatarIco from './images/avatarbm.png';
import React, { useEffect, useState } from 'react';
import addressIco from './images/address-book-solid.svg';
import gitIco from './images/github.svg';
import hatIco from './images/hat-wizard-solid.svg';
import linkedIco from './images/linkedin.svg';
import mugIco from './images/mug-hot-solid.svg';

function App() {
  const [modal, contextHolder] = Modal.useModal();
  const [animation, setAnimation] = useState(0);
  const items = [1,2,3,4,5];

  const toggleContactOpen = () => {
    modal.info({
      title: 'Contact Aaron',
      content: <code>I can be contacted by email for custom website & software requests at: <a className='link' href="mailto:aaronalnuttpro@gmail.com?subject=Reaching out from your website">aaronalnuttpro@gmail.com</a></code>,
      icon: null
    })
  }

  const navGitHub = () => window.open('https://github.com/coderedm0nztR');
  const navLinkedin = () => window.open('https://www.linkedin.com/in/aaron-alnutt-1bba20176/');

  useEffect(() => {
    const t = setTimeout(() => {
      setAnimation(_animation => (_animation + 1) % 6)
    }, 2000);
    return () => clearTimeout(t)
  }, [animation]);


  return (
    
    <section className="App-body">     

      <p className={`App-conversion ${animation === 4 ? 'delayShake' : ''}`}onClick={navLinkedin}>
        <img className="App-conversion-icon blue-svg" src={linkedIco}  />
        <code>linkedin</code>
      </p>
      <p className={`App-conversion ${animation === 5 ? 'delayShake' : ''}`} onClick={navGitHub}>
        <img className="App-conversion-icon purple-svg" src={gitIco}  />
        <code>github</code>
      </p>
      
      <p className={`App-conversion ${animation === 2 ? 'delayShake' : ''}`} onClick={() => window.location = '/examples'}>
        <img className="App-conversion-icon brown-svg" src={mugIco}  />
        <code>samples</code>
      </p>
      <p className={`App-conversion ${animation === 3 ? 'delayShake' : ''}`} onClick={toggleContactOpen}>
        <img className="App-conversion-icon orange-svg" src={addressIco}  />
        <code>contact</code>
      </p>
      <p className={`App-conversion ${animation === 1 ? 'delayShake' : ''}`} onClick={() => window.location = '/about'}>
        <img className="App-conversion-icon green-svg" src={hatIco}  />
        <code>about</code>
      </p>
      {/* <a
        className="App-link"
        href="https://reactjs.org"
        target="_blank"
        rel="noopener noreferrer"
      >
        Learn React
      </a> */}
      <div>{contextHolder}</div>
    </section>
  );
}

export default App;
