import logo from '../logo.svg';
import '../App.css';
import { Avatar, Card, Col, Row, Modal, Typography } from 'antd';
import homepage from '../images/ticket_landing.png';
import pos from '../images/ticket_pos.png';
import template from '../images/ticket_templates.png';
import reportDates from '../images/ticket_report_selector.png';
import report from '../images/ticket_reports.png';
import { useEffect, useState } from 'react';


function TicketSys() {


  const [modal, contextHolder] = Modal.useModal();

  
  return (
    <div>
      <div>{contextHolder}</div>
      <section className="Examples-body">
        {/* <img src={logo} className="App-logo" alt="logo" /> */}
        
        <p className='header'>
          Ticket POS System<Typography.Text type='secondary' level={3}>
          &nbsp;built with WPF
        </Typography.Text>
        </p>
        
        <Typography.Title level={3}>
          Homescreen
        </Typography.Title>
        <img style={{ maxWidth: '100%' }} className='example-link' src={homepage} />
        <br /><br />
        <Typography.Title level={3}>
          Point of Sales interface
        </Typography.Title>
        <img style={{ maxWidth: '100%' }} className='example-link' src={pos} />
        <br /><br />
        <Typography.Title level={3}>
          Show Template Editor
        </Typography.Title>
        <img style={{ maxWidth: '100%' }} className='example-link' src={template} />
        <br /><br />
        <Typography.Title level={3}>
          Report Viewer
        </Typography.Title>
        <img style={{ maxWidth: '100%' }} className='example-link' width={600} src={reportDates} />
        <br  />
        <img style={{ maxWidth: '100%' }} className='example-link' src={report} />
        <br /><br />
      </section>
    </div>
  );
}

export default TicketSys;
