import { Space } from "antd";
import React, { useState } from "react";
import stockinggif from './images/stockinggif.gif';
const Code = () => {

    const [code, setCode] = useState();
    const [showMaxAndJoan, setShowMaxAndJoan] = useState(false);

    const onOk = () => {
        const cDate = new Date();

        if (code === 'maxandjoan2023' && cDate.getFullYear() == 2023 && [24, 25].includes(cDate.getDate())  && cDate.getMonth() == 11)
            setShowMaxAndJoan(true);

        
    }


    return (
        <Space direction="vertical">
            <Space style={{ marginTop: 50 }}>
                <span>Enter Code:</span>
                <input style={{ borderRadius: 5 }} type="text" value={code} onChange={e => setCode(e.target.value)} />
                <button onClick={onOk}>Enter</button>
            </Space>
            <img src={stockinggif} height={200} />


        </Space>
    )
};

export default Code;